<template>
  <div class="email-request flex flex-col items-center pt-64 pb-100 px-64 text-grey-900">
    <img src="/imgs/password.svg" alt="padlock" />

    <h1 class="mt-24 text-32 leading-150 tracking-038">
      {{ $t('MODALS.SIGNUP_TITLE') }}
    </h1>

    <h2 class="mt-17 font-bbva text-18 leading-153 font-medium">
      {{ $t('MODALS.EMAIL.SUBTITLE') }}
    </h2>

    <p class="mt-16 text-18 leading-156 text-center">{{ $t('MODALS.EMAIL.TEXT') }}</p>

    <app-input
      v-model="email"
      type="email"
      :error="$v.email.$invalid && $v.email.$dirty"
      :placeholder="$t('MODALS.EMAIL.PLACEHOLDER')"
      class="input mt-31"
      @input="$v.email.$touch()"
    />

    <app-button
      class="continue mt-56 bg-green-400 font-bbva"
      :disabled="$v.email.$invalid"
      @click="sendEmail()"
      >{{ $t('SHARED.CONTINUE') }}</app-button
    >

    <a
      href="javascript: void(0)"
      class="mt-16 text-blue-400 font-bbva font-medium text-15 leading-160"
      @click="$modal.close()"
      >{{ $t('SHARED.CANCEL') }}</a
    >
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required, email } from 'vuelidate/lib/validators'
import { mapMutations } from 'vuex'
import { AppInput } from '@/components/form'
import AppButton from '@/components/AppButton'
import employeeService from '@/shared/services/employeeService'
import { emailFormatter } from '@/shared/utils/emailFormatter'

export default {
  name: 'EmailRequest',
  components: {
    AppButton,
    AppInput,
  },
  mixins: [validationMixin],
  data: () => ({
    email: undefined,
  }),
  validations: {
    email: {
      required,
      email: (value) => email(emailFormatter(value)),
    },
  },
  methods: {
    ...mapMutations('portal', ['setRequestInProgressStatus']),
    sendEmail() {
      this.setRequestInProgressStatus(true)
      employeeService
        .registerEmail(this.email)
        .then(() => {
          this.$modal.close(true, {
            email: this.email,
          })
        })
        .finally(() => {
          this.setRequestInProgressStatus(false)
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.email-request {
  .input {
    width: 326px;
  }

  .continue {
    width: 205px;
  }
}
</style>
