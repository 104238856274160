<template>
  <div class="modal-phone-request flex flex-col items-center pt-64 pb-100 px-64 text-grey-900">
    <img src="/imgs/password.svg" alt="padlock" />

    <h1 class="mt-24 text-32 leading-150 tracking-038">
      {{ $t('MODALS.SIGNUP_TITLE') }}
    </h1>

    <h2 class="mt-17 font-bbva text-18 leading-153 font-medium">
      {{ $t('MODALS.PHONE_REQUEST.SUBTITLE') }}
    </h2>

    <p class="mt-16 text-18 leading-156 text-center">{{ $t('MODALS.PHONE_REQUEST.TEXT') }}</p>

    <app-input
      v-model="phone"
      type="text"
      :error="$v.phone.$invalid && $v.phone.$dirty"
      :maxlength="9"
      :chars="/[0-9]/"
      :placeholder="$t('MODALS.PHONE_REQUEST.PLACEHOLDER')"
      class="input-phone mt-31"
      @input="$v.phone.$touch()"
    />

    <app-button
      class="continue mt-56 bg-green-400 font-bbva"
      :disabled="$v.phone.$invalid"
      @click="sendPhone()"
      >{{ $t('SHARED.CONTINUE') }}</app-button
    >

    <a
      href="javascript: void(0)"
      class="mt-16 text-blue-400 font-bbva font-medium text-15 leading-160"
      @click="$modal.close()"
      >{{ $t('SHARED.CANCEL') }}</a
    >
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required, minLength, maxLength } from 'vuelidate/lib/validators'
import { mapMutations } from 'vuex'
import { AppInput } from '@/components/form'
import AppButton from '@/components/AppButton'
import employeeService from '@/shared/services/employeeService'

export default {
  name: 'PhoneRequest',
  components: {
    AppButton,
    AppInput,
  },
  mixins: [validationMixin],
  data: () => ({
    phone: undefined,
  }),
  validations: {
    phone: {
      required,
      minLength: minLength(9),
      maxLength: maxLength(9),
    },
  },
  methods: {
    ...mapMutations('portal', ['setRequestInProgressStatus']),
    sendPhone() {
      this.setRequestInProgressStatus(true)
      employeeService
        .registerPhone(this.phone)
        .then(() => {
          this.$modal.close(true, {
            phone: this.phone,
          })
        })
        .finally(() => {
          this.setRequestInProgressStatus(false)
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.modal-phone-request {
  .input-phone {
    width: 326px;
  }

  .continue {
    width: 205px;
  }
}
</style>
