<template>
  <div class="auth-success flex flex-col items-center pt-64 pb-100 px-64 text-grey-900">
    <img class="image mb-41" src="/imgs/success.svg" :alt="$t('SHARED.CLOSE')" />
    <h1 class="mt-24 mb-16 text-32 leading-150 tracking-038">
      {{ $t('MODALS.SUCCESS.TITLE') }}
    </h1>
    <p class="message mb-64 text-18 text-center color-grey-900">
      {{ $t('MODALS.SUCCESS.MESSAGE') }}
    </p>
    <app-button class="continue bg-green-400 font-bbva" @click="navigateTo()">{{
      $t('MODALS.SUCCESS.ACCESS')
    }}</app-button>
  </div>
</template>

<script>
import AppButton from '@/components/AppButton'

export default {
  name: 'AuthSuccess',
  components: {
    AppButton,
  },
  props: {
    maskedPhone: {
      type: String,
      required: true,
    },
    maskedEmail: {
      type: String,
      required: true,
    },
    url: {
      type: String,
      required: true,
    },
  },
  methods: {
    navigateTo() {
      this.$router.push(this.url)
      this.$modal.close()
    },
  },
}
</script>

<style lang="scss">
.auth-success {
  .message {
    width: 100%;
    max-width: 585px;
  }
}
</style>
