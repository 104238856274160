<template>
  <div class="modal-sae-request flex flex-col items-center pt-64 pb-100 px-64 text-grey-900">
    <img src="/imgs/password.svg" alt="padlock" />

    <h1 class="mt-24 text-32 leading-150 tracking-038">
      {{ $t('MODALS.SIGNUP_TITLE') }}
    </h1>

    <h2 class="mt-17 font-bbva text-18 leading-153 font-medium">
      {{ $t('MODALS.SAE.SUBTITLE') }}
    </h2>

    <p class="modal-sae-text mt-16 text-18 leading-156 text-center">{{ $t('MODALS.SAE.TEXT') }}</p>

    <app-input
      v-model="sae"
      type="password"
      :placeholder="$t('MODALS.SAE.PLACEHOLDER')"
      class="input-sae mt-31"
    />

    <app-button class="continue mt-56 bg-green-400 font-bbva" @click="sendSae()">{{
      $t('SHARED.CONTINUE')
    }}</app-button>

    <a
      href="javascript: void(0)"
      class="mt-16 text-blue-400 font-bbva font-medium text-15 leading-160"
      @click="$modal.close()"
      >{{ $t('SHARED.CANCEL') }}</a
    >
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
import { AppInput } from '@/components/form'
import AppButton from '@/components/AppButton'
import employeeService from '@/shared/services/employeeService'

export default {
  name: 'SaeRequest',
  components: {
    AppButton,
    AppInput,
  },
  data: () => ({
    sae: undefined,
  }),
  methods: {
    ...mapMutations('portal', ['setRequestInProgressStatus']),
    sendSae() {
      this.setRequestInProgressStatus(true)
      employeeService
        .validateSae(this.sae)
        .then((response) => {
          if (response.estado === 'OK') {
            this.$modal.close(true, {
              sae: this.sae,
            })
          } else if (response.estado === 'KO') {
            this.$modal.close(false)
          }
        })
        .finally(() => {
          this.setRequestInProgressStatus(false)
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.modal-sae-request {
  .modal-sae-text {
    max-width: 400px;
  }

  .input-sae {
    width: 326px;
  }

  .continue {
    width: 205px;
  }
}
</style>
