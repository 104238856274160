import { render, staticRenderFns } from "./EmailValidation.vue?vue&type=template&id=91faff50&scoped=true&"
import script from "./EmailValidation.vue?vue&type=script&lang=js&"
export * from "./EmailValidation.vue?vue&type=script&lang=js&"
import style0 from "./EmailValidation.vue?vue&type=style&index=0&id=91faff50&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "91faff50",
  null
  
)

export default component.exports