<template>
  <div>
    <app-section
      class="part-top bg-blue-750 bg-center bg-cover bg-no-repeat pt-94"
      :style="bgImage"
    >
      <h1 class="title font-bbva text-48 font-medium leading-110 text-white mb-45">
        {{ page.header }}
      </h1>
      <app-rule width="100px" color="white" />
      <app-button class="button bg-green-300 mt-45" @click="navigateToLogin">{{
        $t('AUTH.ACCESS')
      }}</app-button>
    </app-section>

    <app-section class="part-bottom text-center pt-106 pb-100">
      <h2 class="text-42 text-grey-900">{{ page.title }}</h2>
      <p class="text-20 leading-150 text-grey-500">{{ page.text }}</p>

      <div class="my-47">
        <a
          class="font-bbva font-medium text-21 text-blue-400 mb-6"
          :href="guideHref"
          target="_blank"
        >
          <em> {{ $t('AUTH.LOGIN_GUIDE') }}</em>
        </a>
      </div>

      <img class="h-[107px] mx-auto mt-90" src="/imgs/email.svg" alt="icono de Correo" />

      <h3 class="font-bbva font-medium text-18 text-black mb-6">{{ page.titleContact }}</h3>
      <div class="font-bbva font-medium text-18 text-blue-400 mb-6">
        <a :href="mailHref" target="_blank">{{ page.mail }}</a>
      </div>
    </app-section>

    <app-swap-id v-if="isSwapIdEnabled" @swapEmployee="swapEmployee"></app-swap-id>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'
import { dcLoginUrl, seniorLoginUrl } from '@/shared/constants/api.routes'
import * as modals from './modals'
import { isDcUrl } from '@/shared/utils/isDcUrl'
import HomeUrl from '@/mixins/HomeUrl'
import Alert from '@/components/modals/Alert.vue'

const modalOptions = {
  closeOnClickMask: false,
}

export default {
  name: 'Auth',

  components: {
    AppSection: () => import('@/components/AppSection'),
    AppRule: () => import('@/components/AppRule'),
    AppButton: () => import('@/components/AppButton'),
    AppSwapId: () => import('@/components/AppSwapId'),
  },

  mixins: [HomeUrl],

  computed: {
    ...mapState('auth', ['user']),
    ...mapGetters('auth', [
      'authenticated',
      'userValidated',
      'maskedPhone',
      'maskedEmail',
      'isForbidden',
    ]),
    ...mapState('portal', ['authId', 'homeId', 'pages']),

    page() {
      return this.pages[this.authId] || {}
    },

    bgImage() {
      if (!this.page.image) {
        return
      }

      return {
        backgroundImage: `url("${this.page.image.src}")`,
      }
    },

    guideHref() {
      return isDcUrl()
        ? '/guides/dc-fast-guide-for-login.pdf'
        : '/guides/senior-fast-guide-for-login.pdf'
    },

    mailHref() {
      return 'mailto:' + this.page.mail
    },

    isSwapIdEnabled() {
      return process.env.VUE_APP_ENV !== 'PRO' && process.env.VUE_APP_AUTHENTICATE === 'false'
    },
  },

  created() {
    const { bbva_key, cms_token } = this.$route.query

    if (bbva_key && cms_token) {
      this.setUid(bbva_key)
      this.setToken(cms_token)
    }

    if (this.authenticated) {
      this.login()
    }
  },

  beforeMount() {
    this.loadAuth()
  },

  methods: {
    ...mapActions('auth', ['setUid', 'setToken', 'loadUser']),
    ...mapActions('portal', ['loadAuth']),
    ...mapMutations('portal', ['setRequestInProgressStatus']),

    swapEmployee(uid, token = process.env.VUE_APP_CMS_TOKEN) {
      this.setUid(uid)
      this.setToken(token)
      this.login()
    },

    async login() {
      this.setRequestInProgressStatus(true)
      await this.loadUser()
      this.setRequestInProgressStatus(false)

      this.isForbidden ? this.openForbiddenModal() : this.checkRedirectHome()
    },

    navigateToLogin() {
      const loginUrl = isDcUrl() ? dcLoginUrl : seniorLoginUrl
      window.location.href = loginUrl
    },

    openForbiddenModal() {
      const props = {
        translationKeys: {
          title: 'AUTH.MODALS.FORBIDDEN.TITLE',
          text: 'AUTH.MODALS.FORBIDDEN.TEXT',
        },
      }
      this.$modal.open(Alert, props, modalOptions)
    },

    checkRedirectHome() {
      if (this.userValidated) {
        this.goHome()
        return
      }

      if (this.user) {
        this.checkOpenModal()
      }
    },

    checkOpenModal() {
      if (!this.user.validationSae) {
        this.openSaeRequest()
      } else if (!this.user.validationPhone) {
        this.openPhoneRequest()
      } else if (!this.user.validationEmail) {
        this.openEmailRequest()
      } else {
        this.openAuthSuccess()
      }
    },

    openSaeRequest() {
      this.$modal
        .open(modals.SaeRequest, {}, modalOptions)
        .then((response) => {
          if (response.sae) {
            this.user.validationSae = response.sae
            this.checkOpenModal()
          }
        })
        .catch(() => {
          this.openSaeError()
        })
    },

    openSaeError() {
      this.$modal.open(modals.SaeError, {}, modalOptions).then((response) => {
        if (response.retry) {
          this.openSaeRequest()
        }
      })
    },

    openPhoneRequest() {
      this.$modal.open(modals.PhoneRequest, {}, modalOptions).then((response) => {
        if (response.phone) {
          this.user.validationPhone = response.phone
          this.openPhoneValidation(response.phone)
        }
      })
    },

    openPhoneValidation(phone) {
      const props = {
        maskedPhone: this.maskedPhone,
        phone,
        allowRequestAgain: true,
        translationKeys: {
          title: 'MODALS.SIGNUP_TITLE',
          subtitle: 'MODALS.PHONE_VALIDATION.SUBTITLE',
        },
      }

      this.$modal.open(modals.PhoneValidation, props, modalOptions).then((response) => {
        if (response.validated) {
          this.checkOpenModal()
        } else if (response.requestAgain) {
          this.openPhoneRequest()
        } else {
          this.user.validationPhone = undefined
        }
      })
    },

    openEmailRequest() {
      this.$modal.open(modals.EmailRequest, {}, modalOptions).then((response) => {
        if (response.email) {
          this.user.validationEmail = response.email
          this.openEmailValidation(response.email)
        }
      })
    },

    openEmailValidation(email) {
      const props = {
        maskedEmail: this.maskedEmail,
        email,
        allowRequestAgain: true,
        translationKeys: {
          title: 'MODALS.SIGNUP_TITLE',
          subtitle: 'MODALS.EMAIL_VALIDATION.SUBTITLE',
        },
      }

      this.$modal.open(modals.EmailValidation, props, modalOptions).then((response) => {
        if (response.validated) {
          this.checkOpenModal()
        } else if (response.requestAgain) {
          this.openEmailRequest()
        } else {
          this.user.validationEmail = undefined
        }
      })
    },

    openAuthSuccess() {
      this.$modal.open(
        modals.AuthSuccess,
        {
          maskedPhone: this.maskedPhone,
          maskedEmail: this.maskedEmail,
          url: '/viewer/' + this.homeId,
        },
        modalOptions
      )
    },
  },
}
</script>

<style lang="scss" scoped>
.part-top {
  height: 545px;

  .title {
    width: 50%;
  }

  .button {
    width: 303px;
  }
}
</style>
