<template>
  <div class="modal-sae-error flex flex-col items-center pt-64 pb-100 px-64 text-grey-900">
    <img src="/imgs/password.svg" alt="padlock" />

    <h1 class="mt-24 text-32 leading-150 tracking-038">
      {{ $t('MODALS.SIGNUP_TITLE') }}
    </h1>

    <h2 class="mt-17 font-bbva text-18 leading-153 font-medium">
      {{ $t('MODALS.SAE.SUBTITLE') }}
    </h2>

    <p class="mt-16 text-18 leading-156 text-center">{{ $t('MODALS.SAE.ERROR_P1') }}</p>

    <a
      href="javascript: void(0)"
      class="mt-20 font-bbva text-blue-400 text-15 font-medium leading-160"
      @click="retry()"
      >{{ $t('MODALS.SAE.RETRY') }}</a
    >

    <app-button class="close mt-56 bg-green-400 font-bbva" @click="$modal.close()">{{
      $t('SHARED.CLOSE')
    }}</app-button>
  </div>
</template>

<script>
import AppButton from '@/components/AppButton'

export default {
  name: 'SaeError',

  components: {
    AppButton,
  },

  methods: {
    retry() {
      this.$modal.close(true, {
        retry: true,
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.modal-sae-error {
  .close {
    width: 205px;
  }
}
</style>
