<template>
  <div class="email-validation flex flex-col items-center py-100 px-64 text-grey-900">
    <img src="/imgs/softoken.svg" alt="token" />
    <h1 class="mt-24 text-32 leading-150 tracking-038">
      {{ $t(translationKeys.title) }}
    </h1>
    <h2 v-if="translationKeys.subtitle" class="mt-17 font-bbva text-18 leading-153 font-medium">
      {{ $t(translationKeys.subtitle) }}
    </h2>
    <p class="mt-16 text-18 leading-156 text-center">
      {{ $t('MODALS.EMAIL_VALIDATION.TEXT') }}
    </p>
    <p class="text-18 leading-156 text-center">
      {{ maskedEmail }}
    </p>
    <app-input
      v-model="code"
      type="text"
      :chars="/[0-9]/"
      :maxlength="4"
      :placeholder="$t('MODALS.EMAIL_VALIDATION.PLACEHOLDER')"
      class="input-code mt-31"
      :error="codeError"
    />
    <p v-show="codeError" class="error mt-8 text-12 leading-132 text-grey-900 pl-32">
      {{ $t('MODALS.EMAIL_VALIDATION.ERROR') }}
    </p>
    <app-button class="continue mt-56 bg-green-400 font-bbva" @click="sendCode()">{{
      $t('MODALS.EMAIL_VALIDATION.SEND_CODE')
    }}</app-button>
    <a
      v-if="allowRequestAgain"
      href="javascript: void(0)"
      class="mt-16 text-blue-400 font-bbva font-medium text-15 leading-160"
      @click="requestAgain()"
      >{{ $t('MODALS.EMAIL_VALIDATION.DIDNT_GET') }}</a
    >
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
import { AppInput } from '@/components/form'
import AppButton from '@/components/AppButton'
import employeeService from '@/shared/services/employeeService'

export default {
  name: 'EmailValidation',
  components: {
    AppButton,
    AppInput,
  },
  props: {
    maskedEmail: {
      required: true,
      type: String,
    },
    email: {
      required: true,
      type: String,
    },
    translationKeys: {
      required: true,
      type: Object,
    },
    allowRequestAgain: {
      required: false,
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    codeError: false,
    code: undefined,
  }),
  methods: {
    ...mapMutations('portal', ['setRequestInProgressStatus']),
    sendCode() {
      this.setRequestInProgressStatus(true)
      employeeService
        .validateEmail(this.code, this.email)
        .then((response) => {
          if (response.estado === 'OK') {
            this.$modal.close(true, {
              validated: true,
            })
          } else if (response.estado === 'KO') {
            this.codeError = true
          }
        })
        .finally(() => {
          this.setRequestInProgressStatus(false)
        })
    },
    requestAgain() {
      this.$modal.close(true, {
        requestAgain: true,
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.email-validation {
  .input-code,
  .error {
    width: 326px;
  }

  .error {
    background-image: url('/imgs/warning.svg');
    background-position: 0 8px;
    background-repeat: no-repeat;
    background-size: 16px;
  }

  .continue {
    width: 205px;
  }
}
</style>
